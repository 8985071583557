import React from "react";
import PropTypes from "prop-types";

class CostLine extends React.Component {
  state = {
    isEditing: false,
    label: this.props.label
  };

  toCurrency(number) {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    });

    return formatter.format(number);
  }

  toggleEditing(e) {
    this.setState({isEditing: !this.state.isEditing});
  }

  onChange(event) {
    this.props.setHaulingCost(this.props.name, event.target.value);
  }

  // <input className="col" type="text" pattern="[0-9]+" id={this.state.name} placeholder={this.placeholder} onChange={this.onChange}/>

  render() {
    return (
      <div className='costline-container'>
        <label className="bold table-success wide-label" htmlFor={this.props['name']}>
          {this.state.label}
        </label>
        {this.state.isEditing ? (
          <input
            inputMode='numeric'
            min="0"
            className='costline-input'
            name={this.props.name}
            id={this.props.name}
            value={this.props.value === "0" ? "" : isNaN(this.props.value) ? "" : this.props.value}
            onChange={this.onChange.bind(this)}
            onBlur={this.toggleEditing.bind(this)}
            onFocus={e => e.target.select()}
          />
        ) : (
          <input
            type="text"
            className='costline-input'
            name={this.props.name}
            id={this.props.name}
            value={
              this.props.value === "0"
                ? "$0.00"
                : isNaN(this.props.value) ? ""
                : this.toCurrency(this.props.value)
            }
            onFocus={this.toggleEditing.bind(this)}
          />
        )}
      </div>
    );
  }
}

CostLine.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func
};

export default CostLine;
