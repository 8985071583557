import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import CostLine from "./CostLine";
import {WasteLine} from "./WasteLine";
import Header from "./Header";
import "./App.css";

import {
  machineCost,
  machineLifeBeforeRetrofit,
  dailyOperatingHours,
  daysPerMonth,
  machineRefitCost,
  additionalMachineLifetime,
  cycleTime,
  cycleWeight,
  yearlyCostIncreasePercent,
} from './constants'

class App extends React.Component {
  state = {
    quarts1: {
      name: 'quarts1',
      label: "1",
      value: 0,
      result: 0,
      volume: 0.25,
      volWeightFactor: 2.5
    },
    quarts1p5: {
      name: 'quarts1p5',
      label: "1.5",
      value: 0,
      result: 0,
      volume: 0.375,
      volWeightFactor: 2.5
    },
    quarts2p2: {
      name: 'quarts2p2',
      label: "2.2",
      value: 0,
      result: 0,
      volume: 0.55,
      volWeightFactor: 2.5
    },

    quarts5p4: {
      name: 'quarts5p4',
      label: "5.4",
      value: 0,
      result: 0,
      volume: 1.35,
      volWeightFactor: 2.5
    },

    gallons1: {
      name: 'gallons1',
      label: "1",
      value: 0,
      result: 0,
      volume: 1.0,
      volWeightFactor: 2.5
    },

    gallons2: {
      name: 'gallons2',
      label: "2",
      value: 0,
      result: 0,
      volume: 2.0,
      volWeightFactor: 2.5
    },

    gallons2p7: {
      name: 'gallons2p7',
      label: "2.7",
      value: 0,
      result: 0,
      volume: .25,
      volWeightFactor: 2.5
    },

    gallons3: {
      name: 'gallons3',
      label: "3",
      value: 0,
      result: 0,
      volume: 3.0,
      volWeightFactor: 2.5
    },

    gallons5p7: {
      name: 'gallons5p7',
      label: "5.7",
      value: 0,
      result: 0,
      volume: 5.7,
      volWeightFactor: 2.5
    },

    gallons8: {
      name: 'gallons8',
      label: "8",
      value: 0,
      result: 0,
      volume: 8.0,
      volWeightFactor: 2.5
    },

    gallons9: {
      name: 'gallons9',
      label: "9",
      value: 0,
      result: 0,
      volume: 9.0,
      volWeightFactor: 2.5
    },

    gallons20: {
      name: 'gallons20',
      label: "20",
      value: 0,
      result: 0,
      volume: 20.0,
      volWeightFactor: 2.5
    },


    redBags15: {
      name: 'redBags15',
      label: "15",
      value: 0,
      result: 0,
      volume: 15.0,
      volWeightFactor: 1.0
    },

    box: {
      name: 'box',
      label: "19 x 19 x 21\" (28 gal. equiv.)",
      value: 0,
      result: 0,
      volume: 28.0,
      volWeightFactor: 1.5
    },
    currentHaulingCost: 0.0,
    hideDetails: true,
  };

  componentDidMount() {
    document.title = "Sterilis Solutions ROI Calculator";
  }


  poundsPerMonth = () => (
    this.state.quarts1.result +
    this.state.quarts1p5.result +
    this.state.quarts2p2.result +
    this.state.quarts5p4.result +
    this.state.gallons1.result +
    this.state.gallons2.result +
    this.state.gallons2p7.result +
    this.state.gallons3.result +
    this.state.gallons5p7.result +
    this.state.gallons8.result +
    this.state.gallons9.result +
    this.state.gallons20.result +
    this.state.redBags15.result +
    this.state.box.result
  ).toFixed(0);

  cyclesPerMonth = () => Math.ceil(this.poundsPerMonth() / 8.0);

  machinesNeeded = () =>
    Math.ceil(
      this.cyclesPerMonth() /
      ((dailyOperatingHours * 60 * daysPerMonth) / cycleTime)
    );

  adminCost = () =>
    (
      (((dailyOperatingHours * 60) / 12.0) * (75000 / 52 / 40)) /
      12
    ).toFixed(2);

  monthlySterilisCost = () =>
    Math.ceil(
      (this.machinesNeeded() * machineCost) /
      (machineLifeBeforeRetrofit * 12)
    ).toFixed(2);
  monthlyRMWCost = () =>
    this.monthlySterilisCost() === "0.00"
      ? "0"
      : this.state.currentHaulingCost + parseFloat(this.adminCost());
  customerAnnualSavings = () =>
    12 * (this.monthlyRMWCost() - this.monthlySterilisCost());
  customerLifetimeSavings = () =>
    machineLifeBeforeRetrofit * this.customerAnnualSavings() +
    ((this.monthlyRMWCost() * yearlyCostIncreasePercent) / 100) * 12 * 4;

  monthlySterilisCostY6 = () =>
    Math.ceil(
      (this.machinesNeeded() * machineRefitCost) /
      (additionalMachineLifetime * 12)
    );
  monthlyRMWCostY6 = () =>
    this.monthlyRMWCost() +
    this.monthlyRMWCost() *
    (yearlyCostIncreasePercent / 100) *
    additionalMachineLifetime;
  customerAnnualSavingsY6 = () =>
    12 * (this.monthlyRMWCostY6() - this.monthlySterilisCostY6());
  customerLifetimeSavingsY6 = () =>
    this.customerAnnualSavingsY6() * additionalMachineLifetime +
    this.monthlyRMWCostY6() * (yearlyCostIncreasePercent / 100) * 12 * 4;
  customer10YearSavings = () =>
    this.customerLifetimeSavings() + this.customerLifetimeSavingsY6();

  setHaulingCost = (name, value) => {
    if (isNaN(parseFloat(value))) {
      this.setState({currentHaulingCost: 0.0});
    } else {
      this.setState({currentHaulingCost: parseFloat(value)});
    }
  };

  toDecimalDollars = number => {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      currencySign: "accounting"
    });

    return formatter.format(number);
  };

  displayMachineCount = () =>
    this.machinesNeeded() === 0
      ? ""
      : this.machinesNeeded() === 1
      ? "1 machine"
      : this.machinesNeeded() + " machines";


  updateWasteLine = (name, value) => {
    const numericValue = Number(value);
    console.log(typeof numericValue);
    const result = (value * this.state[name].volume * this.state[name].volWeightFactor);
    console.log(typeof result);

    this.setState(prevState => ({
      [name]: {
        ...prevState[name],
        value: value,
        result,
        // above is syntactic sugar for below
        // result: result
      }
    }));
  };

  resetValues = () => {
    this.setState(prevState => ({
      currentHaulingCost : 0,
      quarts1: {
        ...prevState.quarts1,
        value: 0,
        result: 0,
      },
      quarts1p5: {
        ...prevState.quarts1p5,
        value: 0,
        result: 0,
      },
      quarts2p2: {
        ...prevState.quarts2p2,
        value: 0,
        result: 0,
      },
      quarts5p4: {
        ...prevState.quarts5p4,
        value: 0,
        result: 0,
      },
      gallons1: {
        ...prevState.gallons1,
        value: 0,
        result: 0,
      },
      gallons2: {
        ...prevState.gallons2,
        value: 0,
        result: 0,
      },
      gallons2p7: {
        ...prevState.gallons2p7,
        value: 0,
        result: 0,
      },
      gallons3: {
        ...prevState.gallons3,
        value: 0,
        result: 0,
      },
      gallons5p7: {
        ...prevState.gallons5p7,
        value: 0,
        result: 0,
      },
      gallons8: {
        ...prevState.gallons8,
        value: 0,
        result: 0,
      },
      gallons9: {
        ...prevState.gallons9,
        value: 0,
        result: 0,
      },
      gallons20: {
        ...prevState.gallons20,
        value: 0,
        result: 0,
      },
      redBags15: {
        ...prevState.redBags15,
        value: 0,
        result: 0,
      },
      box: {
        ...prevState.box,
        value: 0,
        result: 0,
      },

    }));
  };


  render() {
    const hideDetails = this.state.hideDetails ? {display: "none"} : {};

    return (
      <div className="container">
        <Header/>
        <form autoComplete="off">
          <table className="table table-bordered">
            <tbody>
            <tr className='table-primary'>
              <th scope="row" className="col-1">
                Container Type (Quarts)
              </th>
              <th scope="row" className="col-2">
                Qty (per month)
              </th>
              <th scope="row" className='col-3'>Lbs.</th>
            </tr>

            <WasteLine
              lineInfo={this.state.quarts1}
              updateWasteLine={this.updateWasteLine}
            />

            <WasteLine
              lineInfo={this.state.quarts1p5}
              updateWasteLine={this.updateWasteLine}
            />

            <WasteLine
              lineInfo={this.state.quarts2p2}
              updateWasteLine={this.updateWasteLine}
            />

            <WasteLine
              lineInfo={this.state.quarts5p4}
              updateWasteLine={this.updateWasteLine}
            />

            <tr className="table-primary">
              <th scope="col" className="left-col">
                Container Type (Gallons)
              </th>
              <th scope="col" className="middle-col">
                Qty (per month)
              </th>
              <th scope="col" className='right-col'>Lbs.</th>
            </tr>
            {/*{this.displayLines.slice(4, 12)}*/}
            <WasteLine
              lineInfo={this.state.gallons1}
              updateWasteLine={this.updateWasteLine}
            />

            <WasteLine
              lineInfo={this.state.gallons2}
              updateWasteLine={this.updateWasteLine}
            />

            <WasteLine
              lineInfo={this.state.gallons2p7}
              updateWasteLine={this.updateWasteLine}
            />

            <WasteLine
              lineInfo={this.state.gallons3}
              updateWasteLine={this.updateWasteLine}
            />

            <WasteLine
              lineInfo={this.state.gallons5p7}
              updateWasteLine={this.updateWasteLine}
            />

            <WasteLine
              lineInfo={this.state.gallons8}
              updateWasteLine={this.updateWasteLine}
            />

            <WasteLine
              lineInfo={this.state.gallons9}
              updateWasteLine={this.updateWasteLine}
            />

            <WasteLine
              lineInfo={this.state.gallons20}
              updateWasteLine={this.updateWasteLine}
            />


            <tr className="table-secondary">
              <th scope="col" className="left-col">
                Red Bag Waste (Up to 15 lbs)
              </th>
              <th scope="col" className="middle-col">
                Qty (per month)
              </th>
              <th scope="sol" className='right-col'>Lbs.</th>
            </tr>
            <WasteLine
              lineInfo={this.state.redBags15}
              updateWasteLine={this.updateWasteLine}
            />

            <tr className="table-secondary">
              <th scope="col" className="left-col">
                4.5 cubic foot Box
              </th>
              <th scope="col" className="middle-col">
                Qty (per month)
              </th>
              <th scope="sol" className='right-col'>Lbs.</th>
            </tr>

            <WasteLine
              lineInfo={this.state.box}
              updateWasteLine={this.updateWasteLine}
            />
            </tbody>
          </table>
          <CostLine
            name="currentHaulingCost"
            setHaulingCost={this.setHaulingCost}
            value={this.state.currentHaulingCost.toString()}
            label="Current Hauling Cost of RMW/Month"
          />

          <table className='table table-bordered'>
            <tbody>
            <tr className="table-success">
              <td className="left-col">
                Results <b>Years 1-5</b>.
              </td>
              <td className="middle-col">{this.displayMachineCount()}</td>
            </tr>
            <tr>
              <th scope="row" className="left-col">
                Monthly Sterilis Solutions Cost
              </th>
              <td className="middle-col">
                {this.toDecimalDollars(this.monthlySterilisCost())}
              </td>
            </tr>
            <tr>
              <th scope="row" className="left-col">
                Monthly RMW Hauling/Admin Costs
              </th>
              <td className="middle-col">
                {this.toDecimalDollars(this.monthlyRMWCost())}
              </td>
            </tr>
            <tr>
              <th scope="row" className="left-col">
                Customer Annual Savings
              </th>
              <td className="middle-col">
                {this.toDecimalDollars(this.customerAnnualSavings())}
              </td>
            </tr>
            <tr>
              <th scope="row" className="left-col">
                Customer Lifetime Savings incl. YoY hauling
              </th>
              <td className="middle-col">
                {this.toDecimalDollars(this.customerLifetimeSavings())}
              </td>
            </tr>

            <tr className="table-success">
              <td className="left-col">
                Results <b>Years 6-10</b>.{" "}
              </td>
              <td className="middle-col">{this.displayMachineCount()}</td>
            </tr>
            <tr>
              <th scope="row" className="left-col">
                Monthly Sterilis Solutions Cost
              </th>
              <td className="middle-col">
                {this.toDecimalDollars(this.monthlySterilisCostY6())}
              </td>
            </tr>
            <tr>
              <th scope="row" className="left-col">
                Monthly RMW Hauling/Admin Costs
              </th>
              <td className="middle-col">
                {this.toDecimalDollars(this.monthlyRMWCostY6())}
              </td>
            </tr>
            <tr>
              <th scope="row" className="left-col">
                Customer Annual Savings
              </th>
              <td className="middle-col">
                {this.toDecimalDollars(this.customerAnnualSavingsY6())}
              </td>
            </tr>
            <tr>
              <th scope="row" className="left-col">
                Customer Lifetime Savings incl. YoY hauling
              </th>
              <td className="middle-col">
                {this.toDecimalDollars(this.customerLifetimeSavingsY6())}
              </td>
            </tr>
            <tr className="table-dark">
              <th scope="row" className="left-col">
                10-year savings
              </th>
              <td className="middle-col">
                {this.toDecimalDollars(this.customer10YearSavings())}
              </td>
            </tr>

            <tr className="table-warning" style={hideDetails}>
              <td className="left-col">Intermediate Calculations</td>
            </tr>
            <tr className="table-info" style={hideDetails}>
              <td className="left-col">Metrics RMW Generation</td>
            </tr>
            <tr style={hideDetails}>
              <th scope="row" className="left-col">
                Machines Needed
              </th>
              <td className="middle-col">{this.machinesNeeded()}</td>
            </tr>
            <tr style={hideDetails}>
              <th scope="row" className="left-col">
                Pounds per Month
              </th>
              <td className="middle-col">{this.poundsPerMonth()}</td>
            </tr>
            <tr style={hideDetails}>
              <th scope="row" className="left-col">
                Cycles per Month
              </th>
              <td className="middle-col">{this.cyclesPerMonth()}</td>
            </tr>
            <tr style={hideDetails}>
              <th scope="row" className="left-col">
                Cycles Weight / Lbs
              </th>
              <td className="middle-col">{cycleWeight}</td>
            </tr>
            <tr style={hideDetails}>
              <th scope="row" className="left-col">
                Admin Cost
              </th>
              <td>{this.toDecimalDollars(this.adminCost())}</td>
            </tr>
            <tr style={hideDetails}>
              <th scope="row" className="left-col">
                Cycle time
              </th>
              <td className="middle-col">{cycleTime}</td>
            </tr>
            <tr style={hideDetails}>
              <th scope="row" className="left-col">
                Yearly Hauling cost increase
              </th>
              <td className="middle-col">
                {yearlyCostIncreasePercent.toFixed(1) + "%"}
              </td>
            </tr>

            <tr className="table-info" style={hideDetails}>
              <td className="left-col">Machine and Facility Detail</td>
            </tr>
            <tr style={hideDetails}>
              <th scope="row" className="left-col">
                Machine Cost
              </th>
              <td className="middle-col">
                {this.toDecimalDollars(machineCost)}
              </td>
            </tr>
            <tr style={hideDetails}>
              <th scope="row" className="left-col">
                Machine life before ReFit
              </th>
              <td className="middle-col">{machineLifeBeforeRetrofit}</td>
            </tr>
            <tr style={hideDetails}>
              <th scope="row" className="left-col">Daily Operating Hours</th>
              <td className="middle-col">{dailyOperatingHours}</td>
            </tr>

            <tr className="table-info" style={hideDetails}>
              <td className="left-col">Machine Refit and Facility Detail</td>
            </tr>
            <tr style={hideDetails}>
              <th scope="row" className="left-col">
                Machine Refit Cost
              </th>
              <td className="middle-col">
                {this.toDecimalDollars(machineRefitCost)}
              </td>
            </tr>
            <tr style={hideDetails}>
              <th scope="row" className="left-col">
                Additional Machine Lifetime
              </th>
              <td className="middle-col">{additionalMachineLifetime}</td>
            </tr>
            <tr style={hideDetails}>
              <th scope="row" className="left-col">
                Daily Operating Hours
              </th>
              <td className="middle-col">{dailyOperatingHours}</td>
            </tr>
            </tbody>
          </table>
        </form>
        <button className='button-col' onClick={() => this.resetValues()}>Clear Form</button>
      </div>
    );
  }
}

export default App;
