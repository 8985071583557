import React from "react";
import logo from "./sterilis_logo.png";

function Header() {
  return <div className="header">
    <img src={logo} alt="Logo"/>
    <p className='header-text'>
      ROI Calculator
    </p>
  </div>
}

export default Header;
