import React from "react";

export const WasteLine = props => {
  const onChange = event => {
    const regexp = new RegExp(`^-?[0-9]*$`);
    const newValue = event.target.value;
    if (regexp.test(newValue)) {
      props.updateWasteLine(
        props.lineInfo.name, newValue
      )
    }
  };

  const roundNumber = num => Math.round( num * 100 + Number.EPSILON ) / 100;

  const {lineInfo} = props;
  const cleanedResult = roundNumber(lineInfo['result']);
  return <tr>
    <td className="left-col">
      <label className="col-center" htmlFor={lineInfo['name']}>
        {lineInfo['label']}
      </label>
    </td>
    <td className="middle-col">
      <input
        id={lineInfo['name']}
        name={lineInfo['name']}
        min="0"
        onChange={onChange}
        value={lineInfo['value']}
        onFocus={e => e.target.select()}
        inputMode='numeric'
        className='wasteline-input'

      />
    </td>
    <td className='right-col'>
      <label>
        {cleanedResult}
      </label>
    </td>
  </tr>
};
